<template>
  <div class="tai-detail my-second bgcolor">
    <Head :title="$t('wallet.Recharge')" :show="true" />
    <div class="recharge">
        <div class="recharge-top">
            <img class="bankimg" :src="require('@/assets/imgs/bankkok.png')" alt="">
            <div class="item">
                <span>
                    ชื่อบัญชี : One Club Bid (วันคลับบิด)
                </span>
            </div>
            <div class="item">
                <span>ธนาคาร : Bangkok Bank (กรุงเทพ) </span>
            </div>
            <div class="item">
                <p>
                    <span>เลขที่บัญชี : </span>
                    <span>232-5-37574-5</span>
                </p>
                <van-button class="copy-btn" @click="onCopy()">{{$t('mine.copy')}}</van-button>
            </div>
            <div class="item borderno">
                <p class="tiptext">{{$t('wallet.tip1')}}</p>
                <p class="tiptext">{{$t('wallet.tip2')}}</p>
            </div>
            <!-- <div class="item borderno">
                <p class="tiptext">{{$t('wallet.tip2')}}</p>
            </div> -->
        </div>
        <div class="pay-one chong-pop recharge-list">

            <van-form  class="pay-form" label-width="200" ref="payform"  @submit="gotoPayout">
                <van-field
                    @click="showCardAndGet"
                    :label="$t('wallet.bank')"
                    :placeholder="$t('wallet.empty_bank')"
                    name="bankname"
                    :rules="[{ required: true, message:$t('login.ptian')}]"
                    readonly
                    clickable
                    v-model="recharge.bankname"
                    right-icon="arrow-down"
                >
                </van-field>
                <van-field
                    v-model="recharge.username"
                    :label="$t('wallet.name')"
                    :placeholder="$t('wallet.empty_name')"
                    name="username"
                    readonly
                    :rules="[{ required: true, message:$t('login.ptian')}]"
                >
                </van-field>
                <van-field
                    v-model="recharge.bankaccount"
                    type="text"
                    readonly
                    name="bankaccount"
                    :label="$t('info.bank_account')"
                    :placeholder="$t('info.enter_bank_account')"
                    :rules="[{ required: true, message:$t('login.ptian')}]"
                />
                
                    <van-field
                    :label="$t('wallet.banknum')"
                    :placeholder="$t('wallet.empty_bank')"
                    name="banknum"
                    :rules="[{ required: true, message:$t('login.ptian')}]"
                    readonly
                    v-model="recharge.banknum"
                >
                </van-field>
                <van-field
                    v-model.number="recharge.recharge_amount"
                    :label="$t('wallet.recharge_amount')"
                    :placeholder="$t('wallet.empty_recharge_amount')"
                    name="recharge_amount"
                    :rules="[{ required: true, message:$t('login.ptian')}]"
                ></van-field>
                <div class="add-pic">
                    <p>{{$t('add.ping')}} <span v-if="recharge.img">({{$t('add.open_big')}})</span>   </p>
                    <input  type="file" name="file" id="download" ref="download" style="display:none" @change="uploadFile()" />
                    <div class="photo-item" @click.stop="clicktodo" v-if="!recharge.img"> 
                        <!-- <img :src="require('@/assets/images/add-pic.png')" v-if="imgShow" alt=""> -->
                        <van-icon name="plus"  />
                        <p :class="lang=='cn'?'':'small-text'">{{$t('add.up_ping')}}</p>
                    </div> 
                    <div class="photo-item" v-else> 
                        <img class="newimg" @click="showBigImg" :src="$host+recharge.img" alt="">
                    </div> 
                </div>
                
            </van-form>
            <div class="last-btns">
                <van-button @click="payclick" class="btn-two btn-chong" >{{$t('common.confirm')}}</van-button>
            </div>
        </div>

         <!-- 选择银行卡  -->
        <van-popup  class="login-popup pay-one card-choose" :style="{height:'auto'}" v-model="cardShow" position="bottom"   duration="0.2" >
            <div class="login-popup-box">
                <div class="pop-top">
                    <p>{{$t('wallet.choose_bank')}}</p>
                    <van-icon name="cross" class="pop-close" @click="cardShow=false" />
                </div>
                
                <van-cell @click="choosecard(l)" clickable class="mycard" v-for="(l,i) in cardList" :key="i">
                    <div class="card-item">
                        <div class="card-top">
                            <img :src="l.bankCategory.image" alt="">
                            <p>{{l.bankCategory.name}}</p>
                        </div>
                        <div  class="card-bottom">
                            <p class="card-tit">{{$t('wallet.card_num')}}</p>
                            <p>{{l.banknum}}</p>
                        </div>
                    </div>
                </van-cell>
                <div class="no-card" >
                    <template v-if="user"><p v-if="user.isbank=='0'">{{$t('wallet.no_bank')}}</p></template>
                    <van-button @click="gotowhere('mine-card-add')" class="confirm-btn">{{$t('info.add_card')}}</van-button>
                </div>
            </div>
        </van-popup>

        <!-- 充值成功   提现成功  -->
            <van-popup class="login-popup pay-one" v-model="show" position="bottom"   duration="0.2" >
                <div class="login-popup-box">
                    <div class="pop-top">
                        <p>{{toptxt}}</p>
                        <van-icon name="cross" class="pop-close" @click="show=false" />
                    </div>
                    <div class="pay-two" >
                        <img :src="require('@/assets/images/pay-success.png')" alt="">
                        <p>{{$t('wallet.sub_success')}}</p>
                        <p class="chong-txt">{{$t('wallet.pay_success_txt')}}</p>
                    </div>
                    <div class="pay-two" v-if="false">
                        <img :src="require('@/assets/images/pay-fail.png')" alt="">
                        <p>{{$t('wallet.pay_fail')}}</p>
                    </div>
                    <van-button class="kown-btn" @click="show=false">{{$t('wallet.kown')}}</van-button>
                </div>
            </van-popup>
    </div>
  </div>
</template>
<script>
import {Toast,Notify,ImagePreview} from "vant"
import {mapState,mapMutations,mapGetters} from "vuex"
import {randomString} from "@/common"
export default {
  name:'wallet-thairecharge',
  data(){
    return {
        banknum:"232-5-37574-5",
        cardShow:false,
        recharge:{},
        lang:localStorage.getItem("langData") || 'en',
        file:'',
        show:false,
        toptxt:this.$t('wallet.Recharge'),
    }
  },
    computed:{
        ...mapState(['walletmy','taiprice','cardList','dashboard']),
        // ...mapGetters(['user']),
        user(){
            return JSON.parse(localStorage.getItem("dashboard"))
        }
    },
  mounted(){
      if(!this.user.isbank){
            // 没有绑定 
            this.cardShow = true;
        }else{
            this.$ajax.banklist()
            .then(res=>{
                this.setCardList(res.data)
                this.choosecard(res.data[0])
            })
        }
  },
  methods:{
    showBigImg(){
        ImagePreview([this.$host+this.recharge.img]);
    },
    clicktodo(){
        this.$refs.download.click();
    },
    uploadFile(){
        // 修改头像 
        this.file = this.$refs.download.files[0];
        let formData = new FormData();
        formData.append('head',this.file);
        this.$ajax.uploaduserhead(formData)
        .then(res=>{
            if(res.code==200){
                this.recharge = {
                    ...this.recharge,
                    img:res.data.file_path
                }
            }
        })
    },
    choosecard(item){
        this.card = {...this.card,bankname:item.bankname,banknum:item.banknum,bankid:item.id,username:item.bankusername}
        this.recharge = {...this.recharge,bankaccount:item.bankaccount,bankname:item.bankname,banknum:item.banknum,bankid:item.id,username:item.bankusername}
        this.cardShow  = false;
    },
    ...mapMutations(['setdashboard','setwalletmy','changetaiprice','setCardList']),
    payclick(){
        this.$refs.payform.submit()
    },
    gotowhere(name){
        this.$router.push({
            name
        })
    },
    showCardAndGet(){
        this.cardShow=true ;
    },
    gotoPayout(value){
        if(this.recharge.img){
            this.$ajax.submitRecharge(
                {
                    // ...value,
                    recharge_amount:this.recharge.recharge_amount,
                    account:this.user.uid,
                    nickname:this.user.username,
                    img:this.recharge.img,
                    userbankid:this.recharge.bankid,
                    form_token:randomString(),
                }
            ).then(res=>{
                if(res.code==200){
                    this.show = true;
                    this.toptxt = this.$t('wallet.Recharge')
                    this.recharge = {...this.recharge,recharge_amount:"",img:""}
                }
            })
        }else{
            Notify({
                message: this.$t('add.up_ping'),
                color: '#ad0000',
                background: '#ffe1e1',
            });
        }  
    },
    onCopy() {
        this.copyText('2325375745');
        Toast.success(this.$t('news.copysuccess'))
    },
    copyText(shareLink) {
        var _input = document.createElement("input");   // 直接构建input
        _input.value = shareLink;  // 设置内容
        document.body.appendChild(_input);    // 添加临时实例
        _input.select();   // 选择实例内容
        document.execCommand("Copy");   // 执行复制
        document.body.removeChild(_input); // 删除临时实例
    },
  }
}
</script>